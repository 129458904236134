"use client";

import {
  FileOutlined,
  FlagOutlined,
  HomeOutlined,
  PlayCircleOutlined,
  GiftOutlined,
  UserOutlined,
  ExceptionOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  BgColorsOutlined,
} from "@ant-design/icons";
import {Avatar, Dropdown, Layout as AntdLayout, Menu, Space, Typography} from "antd";
import {NextPage} from "next";
import Link from "next/link";
import {useParams} from "next/navigation";
import {signOut, useSession} from "next-auth/react";
import {useLocale, useTranslations} from "next-intl";
import React, {ReactNode, useEffect, useMemo, useState, useTransition} from "react";
import {createUseStyles, useTheme} from "react-jss";

import {MenuClickEventHandler} from "../../../typings/antd";
import AboutModal from "@/app/_components/about-modal";
import SignInForm from "@/app/_components/sign-in-form";
import {InfoIcon} from "@/assets/icons/Info";
import {MoonIcon} from "@/assets/icons/Moon";
import {PowerIcon} from "@/assets/icons/Power";
import {SunIcon} from "@/assets/icons/Sun";
import useBreakpoint from "@/hooks/useBreakpoint";
import {useThemeActions, useThemeMode} from "@/hooks/useTheme";
import {LocalPublicConfigModule} from "@/modules/public-config";
import {useRouter, usePathname} from "@/navigation";
import {PublicConfigModule} from "@/shared/modules/public-config";
import {GravatarUtils} from "@/shared/utils/gravatar";
import {Locale} from "@/types";

import ProfileDrawer from "./profile-drawer";

const SIDER_COLLAPSED_KEY = "ui.preferences.sider.collapsed";

const useStyles = createUseStyles({
  layout: {
    height: "100vh",
    overflow: "hidden",
  },
  headerContainer: {
    display: "flex",
    height: 48,
    justifyContent: "space-between",
  },
  headerTitle: {
    marginBottom: [0, "!important"],
    cursor: "pointer",
    color: ["#ffffffd9", "!important"],
  },
  userContainer: {
    cursor: "pointer",
  },
  userDataContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  userDataText: {
    color: ["#ffffffd9", "!important"],
  },
  userAvatar: {
    borderColor: "lightgray",
    borderWidth: 2,
    borderStyle: "solid",
  },
  userMenuIcon: {
    height: 20,
    width: 20,
  },
  userMenuSelectedItemLight: {
    color: ["#ed7203", "!important"],
    backgroundColor: "#fff6e6",
  },
  userMenuSelectedItemDark: {
    color: ["#ed7203", "!important"],
    backgroundColor: "#291b20",
  },
  sider: {
    height: "100vh",
  },
  menu: {
    height: "100vh",
  },
  contentContainer: {
    overflowY: "scroll",
    overflowX: "scroll",
  },
});

const InnerLayout: NextPage<{children: ReactNode}> = ({children}) => {
  const translation = useTranslations("layout");
  const locale = useLocale();
  const [_, startTransition] = useTransition();
  const styles = useStyles();
  const theme = useTheme();
  const themeMode = useThemeMode();
  const {toggleMode: toggleThemeMode} = useThemeActions();
  const router = useRouter();
  const pathname = usePathname();
  const params = useParams();
  const {data: session, status: sessionStatus} = useSession();
  const user = session?.user;
  const {lg} = useBreakpoint();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [selectedTheme, setSelectedTheme] = useState("light");

  useEffect(() => {
    PublicConfigModule.getPublicConfigs().then(LocalPublicConfigModule.setPublicConfigs);
    setSelectedLanguage(locale);
    if (themeMode) setSelectedTheme(themeMode);
  }, [locale, themeMode]);

  const defaultSiderCollapsed = window.localStorage.getItem(SIDER_COLLAPSED_KEY) === "true";
  const [siderCollapsed, setSiderCollapsed] = useState(defaultSiderCollapsed);

  const [aboutOpen, setAboutOpen] = useState(false);
  const [profileDrawerOpen, setProfileDrawerOpen] = useState<boolean>(false);

  const avatarUrl = useMemo(() => {
    if (!user?.email) return "";

    const avatarSearchParams = new URLSearchParams({
      size: "40",
      default: `https://www.gravatar.com/avatar/${GravatarUtils.getDefaultEmailHash()}?size=40`,
    });

    const emailHash = GravatarUtils.getEmailHash(user.email);
    return `https://www.gravatar.com/avatar/${emailHash}?${avatarSearchParams.toString()}`;
  }, [user]);

  const handleUserMenuClick: MenuClickEventHandler = ({key}) => {
    switch (key) {
      case "darkMode":
        setSelectedTheme("dark");
        if (themeMode === "light") {
          setTimeout(() => toggleThemeMode(), 400);
        }
        break;
      case "lightMode":
        setSelectedTheme("light");
        if (themeMode === "dark") {
          setTimeout(() => toggleThemeMode(), 400);
        }
        break;
      case "about":
        setAboutOpen(true);
        break;
      case "profile":
        setProfileDrawerOpen(true);
        break;
      case "signOut":
        signOut();
        break;
      case "english":
        setSelectedLanguage("en");
        startTransition(() => {
          router.replace(
            {
              pathname,
              // @ts-expect-error -- TypeScript will validate that only known `params`
              // are used in combination with a given `pathname`. Since the two will
              // always match for the current route, we can skip runtime checks.
              params,
            },
            {locale: "en" as Locale}
          );
        });
        router.refresh();
        break;
      case "spanish":
        setSelectedLanguage("es");
        startTransition(() => {
          router.replace(
            {
              pathname,
              // @ts-expect-error -- TypeScript will validate that only known `params`
              // are used in combination with a given `pathname`. Since the two will
              // always match for the current route, we can skip runtime checks.
              params,
            },
            {locale: "es" as Locale}
          );
        });
        break;
      default:
        break;
    }
  };

  const handleSiderCollapse = (value: boolean) => {
    window.localStorage.setItem(SIDER_COLLAPSED_KEY, value.toString());
    setSiderCollapsed(value);
  };

  const handleTitleClick = () => {
    router.push("/");
  };

  const handleAboutOpenToggle = () => {
    setAboutOpen(!aboutOpen);
  };

  const handleProfileDrawerToggle = () => {
    setProfileDrawerOpen(!profileDrawerOpen);
  };

  const items = useMemo(() => {
    if (!session) {
      return [];
    }
    const menu = [
      {
        key: "/",
        icon: <HomeOutlined />,
        label: translation("homeTitle"),
      },
      {
        key: "/private/playground",
        icon: <PlayCircleOutlined />,
        label: translation("playGroundTitle"),
      },
      {
        key: "/private/logs",
        icon: <ExceptionOutlined />,
        label: translation("logsTitle"),
      },
      {key: "/private/todo", icon: <UnorderedListOutlined />, label: translation("todoTitle")},
    ];
    if (session.roles?.isAdministrator) {
      return [
        ...menu,
        {
          key: "/admin/files",
          icon: <FileOutlined />,
          label: translation("filesTitle"),
        },
        {
          key: "/admin/users",
          icon: <UserOutlined />,
          label: translation("usersTitle"),
        },
        {
          key: "/admin/products",
          icon: <GiftOutlined />,
          label: translation("productsTitle"),
        },
        {
          key: "/admin/countries",
          icon: <FlagOutlined />,
          label: translation("countriesTitle"),
        },
        {
          key: "/admin/public-configs",
          icon: <SettingOutlined />,
          label: translation("publicConfigsTitle"),
        },
      ];
    }
    return menu;
  }, [session, translation]);

  return (
    <AntdLayout className={styles.layout}>
      <AntdLayout.Header>
        <Space className={styles.headerContainer}>
          <Space>
            <Typography.Title className={styles.headerTitle} level={lg ? 3 : 5} onClick={handleTitleClick}>
              OL - Backoffice
            </Typography.Title>
          </Space>
          {session && (
            <Space direction="horizontal" size={10}>
              <Dropdown
                placement="bottomRight"
                menu={{
                  items: [
                    {
                      key: "profile",
                      icon: <UserOutlined className={styles.userMenuIcon} />,
                      label: translation("innerLayoutProfile"),
                    },
                    {
                      key: "language", // Nuevo ítem para la selección de idioma
                      label: "Language",
                      icon: <FlagOutlined className={styles.userMenuIcon} />,
                      children: [
                        {
                          key: "english",
                          label: "English",
                          className:
                            selectedLanguage === "en"
                              ? selectedTheme === "light"
                                ? styles.userMenuSelectedItemLight
                                : styles.userMenuSelectedItemDark
                              : "",
                        },
                        {
                          key: "spanish",
                          label: "Spanish",
                          className:
                            selectedLanguage === "es"
                              ? selectedTheme === "light"
                                ? styles.userMenuSelectedItemLight
                                : styles.userMenuSelectedItemDark
                              : "",
                        },
                      ],
                    },
                    {
                      key: "toggleThemeMode",
                      icon: <BgColorsOutlined className={styles.userMenuIcon} />,
                      label: "Theme",
                      children: [
                        {
                          key: "lightMode",
                          label: "Light Mode",
                          icon: <SunIcon className={styles.userMenuIcon} />,
                          className: selectedTheme === "light" ? styles.userMenuSelectedItemLight : "",
                        },
                        {
                          key: "darkMode",
                          label: "Dark Mode",
                          icon: <MoonIcon className={styles.userMenuIcon} />,
                          className: selectedTheme === "dark" ? styles.userMenuSelectedItemDark : "",
                        },
                      ],
                    },
                    {
                      key: "about",
                      icon: <InfoIcon className={styles.userMenuIcon} />,
                      label: translation("innerLayoutAbout"),
                    },
                    {type: "divider"},
                    {
                      key: "signOut",
                      icon: <PowerIcon className={styles.userMenuIcon} />,
                      label: translation("innerLayoutSignOut"),
                    },
                  ],
                  style: {width: "max-content"},
                  onClick: handleUserMenuClick,
                }}
                trigger={["click"]}>
                <Space className={styles.userContainer}>
                  <div className={styles.userDataContainer}>
                    <Typography.Text className={styles.userDataText} strong>
                      {session.user.name}
                    </Typography.Text>
                    <Typography.Text className={styles.userDataText}>
                      {session.user.email.split("@")[0]}
                    </Typography.Text>
                  </div>
                  <Avatar className={styles.userAvatar} size={38} src={avatarUrl} alt={session.user.name} />
                </Space>
              </Dropdown>
            </Space>
          )}
        </Space>
        <AboutModal open={aboutOpen} onCancel={handleAboutOpenToggle}></AboutModal>
      </AntdLayout.Header>
      <AntdLayout hasSider>
        {session && (
          <AntdLayout.Sider
            className={styles.sider}
            collapsible
            defaultCollapsed={defaultSiderCollapsed}
            collapsed={siderCollapsed}
            onCollapse={handleSiderCollapse}>
            <Menu
              className={styles.menu}
              mode="inline"
              theme={theme.isLight ? "dark" : undefined}
              items={items}
              defaultSelectedKeys={["/"]}
              selectedKeys={pathname ? [pathname] : []}
              _internalRenderMenuItem={(originNode, menuItemProps) => (
                <Link href={menuItemProps.eventKey} legacyBehavior>
                  {originNode}
                </Link>
              )}
            />
          </AntdLayout.Sider>
        )}

        <AntdLayout>
          <AntdLayout.Content className={styles.contentContainer}>
            {sessionStatus === "unauthenticated" && !session && <SignInForm />}
            {session && children}
          </AntdLayout.Content>
        </AntdLayout>
      </AntdLayout>
      <ProfileDrawer profileDrawerOpen={profileDrawerOpen} handleProfileDrawerToggle={handleProfileDrawerToggle} />
    </AntdLayout>
  );
};

export default InnerLayout;
