import {Switch, Typography} from "antd";
import React from "react";

import type {WidgetProps} from "@/app/_components/form-builder";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface SwitchWidgetProps extends WidgetProps<boolean | null> {}

export const SwitchWidget = ({onChange, value}: SwitchWidgetProps) => {
  const renderBoolean = (bool: boolean | null) => {
    return bool ? "Yes" : "No";
  };

  const handleBoolChange = (bool: boolean) => {
    if (onChange) {
      onChange(bool);
    }
  };

  return !onChange ? (
    <Typography.Text>{renderBoolean(value)}</Typography.Text>
  ) : (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Switch onChange={handleBoolChange} value={value} />
  );
};
